.homepage-splash {
    height: 100vh;
    text-align: center;
    position: relative;
    min-height: 555px;
    
    .more-news {
        @extend %more;
    }
    
    .logo {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        
        .more-news {
            display: none;
            margin-top: $grid * 5;
            
            @media (max-height: 630px) {
                display: block;
            }
        }
    }
    
    .enter {
        text-transform: uppercase;
        font-size: 1.2em;
    }
    
    .footer {
        position: absolute;
        left: 0;
        right: 0;
        bottom: $grid * 3;
        
        .more-news {
            margin-bottom: 5vh;
            
            @media (max-height: 630px) {
                display: none;
            }
        }
    }
}

.home-news {
    .more-news {
        margin-top: $grid * 4;
        margin-bottom: $grid * 4;
        
        @extend %more;
        
        a {
            background: url('../img/arrRight.png') no-repeat 100% 50%;
        }
    }
}

.home-social-feeds {
    display: flex;
    justify-content: space-between;
    
    & > * {
        width: 30%;
    }
    
    .social-name {
        font-weight: 700;
        text-transform: uppercase;
    }
    
    .container-instagram-feed,
    .js-tumblr-container {
        height: 514px;
        overflow: auto;
    }
}

.container-instagram-feed {
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    
    li {
        width: 48.5%;
    }
}