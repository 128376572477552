.contact-page {
    .address, .get-in-touch {
        text-align: center;
    }
    
    .address {
        font-weight: bold;
        margin-top: $grid * 2;
        margin-bottom: $grid * 3;
        
        .desk {
            font-size: 1.2em;
        }
    }
}
