.board-page {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    
    .board-item {
        width: $grid * 30;
        text-align: center;
        margin-left: $grid;
        margin-right: $grid;
        margin-bottom: $grid * 4;
        
        .border {
            position: absolute;
            z-index: 1;
            background-color: #000;
            
            &.a {
                $width: 1px;
                $space: 25px;
                $offset: 4px;
                
                &.top {
                    height: $width;
                    top: $offset;
                    left: $space;
                    right: $space;
                } &.right {
                    width: $width;
                    top: $space;
                    right: $offset;
                    bottom: $space;
                } &.bottom {
                    height: $width;
                    bottom: $offset;
                    left: $space;
                    right: $space;
                } &.left {
                    width: $width;
                    left: $offset;
                    top: $space;
                    bottom: $space;
                }
            }
            
            &.c {
                $width: 1px;
                $space: 37px;
                $offset: 16px;
                
                &.top {
                    height: $width;
                    top: $offset;
                    left: $space;
                    right: $space;
                } &.right {
                    width: $width;
                    top: $space;
                    right: $offset;
                    bottom: $space;
                } &.bottom {
                    height: $width;
                    bottom: $offset;
                    left: $space;
                    right: $space;
                } &.left {
                    width: $width;
                    left: $offset;
                    top: $space;
                    bottom: $space;
                }
            }
            
            &.e {
                $width: 1px;
                $space: 37px;
                $offset: 19px;
                
                &.top {
                    height: $width;
                    top: $offset;
                    left: $space;
                    right: $space;
                } &.right {
                    width: $width;
                    top: $space;
                    right: $offset;
                    bottom: $space;
                } &.bottom {
                    height: $width;
                    bottom: $offset;
                    left: $space;
                    right: $space;
                } &.left {
                    width: $width;
                    left: $offset;
                    top: $space;
                    bottom: $space;
                }
            }
            
            &.f {
                $width: 1px;
                $space: 31px;
                $offset: 13px;
                
                &.top {
                    height: $width;
                    top: $offset;
                    left: $space;
                    right: $space;
                } &.right {
                    width: $width;
                    top: $space;
                    right: $offset;
                    bottom: $space;
                } &.bottom {
                    height: $width;
                    bottom: $offset;
                    left: $space;
                    right: $space;
                } &.left {
                    width: $width;
                    left: $offset;
                    top: $space;
                    bottom: $space;
                }
            }
            
            &.g {
                $width: 1px;
                $space: 110px;
                $offset: 13px;
                
                &.top {
                    height: $width;
                    top: $offset;
                    left: $space;
                    right: $space;
                } &.right {
                    width: $width;
                    top: $space;
                    right: $offset;
                    bottom: $space;
                } &.bottom {
                    height: $width;
                    bottom: $offset;
                    left: $space;
                    right: $space;
                } &.left {
                    width: $width;
                    left: $offset;
                    top: $space;
                    bottom: $space;
                }
            }
            
            &.h {
                $width: 1px;
                $space: 32px;
                $offset: 21px;
                
                &.top {
                    height: $width;
                    top: $offset;
                    left: $space;
                    right: $space;
                } &.right {
                    width: $width;
                    top: $space;
                    right: $offset;
                    bottom: $space;
                } &.bottom {
                    height: $width;
                    bottom: $offset;
                    left: $space;
                    right: $space;
                } &.left {
                    width: $width;
                    left: $offset;
                    top: $space;
                    bottom: $space;
                }
            }
        }
        
        .corner {
            position: absolute;
            z-index: 4;
            background-repeat: no-repeat;
            
            &.a {
                width: 22px;
                height: 22px;
                $offset-x: 4px;
                $offset-y: 4px;
                
                &.top-left {
                    top: $offset-y;
                    left: $offset-x;
                    background-image: url('../img/corner-top-left-a.svg');
                } &.top-right {
                    top: $offset-y;
                    right: $offset-x;
                    background-image: url('../img/corner-top-right-a.svg');
                } &.bottom-right {
                    bottom: $offset-y;
                    right: $offset-x;
                    background-image: url('../img/corner-bottom-right-a.svg');
                } &.bottom-left {
                    bottom: $offset-y;
                    left: $offset-x;
                    background-image: url('../img/corner-bottom-left-a.svg');
                }
            }
            &.c {
                width: $grid * 3;
                height: $grid * 3;
                $offset-x: 13px;
                $offset-y: 13px;
                
                &.top-left {
                    top: $offset-y;
                    left: $offset-x;
                    background-image: url('../img/corner-top-left-c.svg');
                } &.top-right {
                    top: $offset-y;
                    right: $offset-x;
                    background-image: url('../img/corner-top-right-c.svg');
                } &.bottom-right {
                    bottom: $offset-y;
                    right: $offset-x;
                    background-image: url('../img/corner-bottom-right-c.svg');
                } &.bottom-left {
                    bottom: $offset-y;
                    left: $offset-x;
                    background-image: url('../img/corner-bottom-left-c.svg');
                }
            }
            &.e {
                width: 24px;
                height: 24px;
                $offset-x: 15px;
                $offset-y: 15px;
                
                &.top-left {
                    top: $offset-y;
                    left: $offset-x;
                    background-image: url('../img/corner-top-left-e.svg');
                } &.top-right {
                    top: $offset-y;
                    right: $offset-x;
                    background-image: url('../img/corner-top-right-e.svg');
                } &.bottom-right {
                    bottom: $offset-y;
                    right: $offset-x;
                    background-image: url('../img/corner-bottom-right-e.svg');
                } &.bottom-left {
                    bottom: $offset-y;
                    left: $offset-x;
                    background-image: url('../img/corner-bottom-left-e.svg');
                }
            }
            &.f {
                width: 20px;
                height: 20px;
                $offset-x: 12px;
                $offset-y: 12px;
                
                &.top-left {
                    top: $offset-y;
                    left: $offset-x;
                    background-image: url('../img/corner-top-left-f.svg');
                } &.top-right {
                    top: $offset-y;
                    right: $offset-x;
                    background-image: url('../img/corner-top-right-f.svg');
                } &.bottom-right {
                    bottom: $offset-y;
                    right: $offset-x;
                    background-image: url('../img/corner-bottom-right-f.svg');
                } &.bottom-left {
                    bottom: $offset-y;
                    left: $offset-x;
                    background-image: url('../img/corner-bottom-left-f.svg');
                }
            }
            &.g {
                width: 107px;
                height: 107px;
                $offset-x: 4px;
                $offset-y: 4px;
                
                &.top-left {
                    top: $offset-y;
                    left: $offset-x;
                    background-image: url('../img/corner-top-left-g.svg');
                } &.top-right {
                    top: $offset-y;
                    right: $offset-x;
                    background-image: url('../img/corner-top-right-g.svg');
                } &.bottom-right {
                    bottom: $offset-y;
                    right: $offset-x;
                    background-image: url('../img/corner-bottom-right-g.svg');
                } &.bottom-left {
                    bottom: $offset-y;
                    left: $offset-x;
                    background-image: url('../img/corner-bottom-left-g.svg');
                }
            }
            &.h {
                width: 20px;
                height: 20px;
                $offset-x: 14px;
                $offset-y: 14px;
                
                &.top-left {
                    top: $offset-y;
                    left: $offset-x;
                    background-image: url('../img/corner-top-left-h.svg');
                } &.top-right {
                    top: $offset-y;
                    right: $offset-x;
                    background-image: url('../img/corner-top-right-h.svg');
                } &.bottom-right {
                    bottom: $offset-y;
                    right: $offset-x;
                    background-image: url('../img/corner-bottom-right-h.svg');
                } &.bottom-left {
                    bottom: $offset-y;
                    left: $offset-x;
                    background-image: url('../img/corner-bottom-left-h.svg');
                }
            }
        }
        
        span {
            display: block;
        }
        
        .shape {
            position: relative;
            z-index: 3;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50% 50%;
        }
        
        .image {
            position: relative;
            padding: $grid * 3;
            line-height: 0;
            
            img {
                width: 100%;
            }
        }
        
        .name {
            text-transform: uppercase;
            font-size: 0.92857142857142857142857142857143em;
            margin-top: $grid;
        }
        
        .hover-layer {
            position: absolute;
            top: $grid * 3;
            right: $grid * 3;
            bottom: $grid * 3;
            left: $grid * 3;
            border: 1px solid #999;
            opacity: 0;
            
            line-height: normal;
        }
        
        .layer {
            position: absolute;
            top: $grid * 3;
            right: $grid * 3;
            bottom: $grid * 3;
            left: $grid * 3;
            background-color: rgba(#000, 0.2);
            z-index: 4;
            
            opacity: 0;
        }
        
        .real {
            position: absolute;
            z-index: 5;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
        }
        
        .hover-name {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            color: #fff;
            z-index: 4;
            text-transform: uppercase;
            font-size: 1.1428571428571428571428571428571em;
        }
        
        .insta-access {
            background: #fff url('../img/instagram.png') no-repeat 50% 50% / cover;
            width: 21px;
            height: 21px;
            margin-left: auto;
            margin-right: auto;
            margin-top: $grid * 2;
        }

        .twitter-access {
            background: #fff url('../img/twitter.png') no-repeat 50% 50% / cover;
            width: 21px;
            height: 21px;
            margin-left: auto;
            margin-right: auto;
            margin-top: $grid * 2;
        }

        a {
            display: block;
            
            &:hover, &:focus {
                .layer {
                    opacity: 1;
                }
                
                .name {
                    opacity: 0;
                }
                
                .hover-layer {
                    opacity: 1;
                }
            }
        }
    }
}
