#main-header {
    text-align: center;
    text-transform: uppercase;
    
    .logo {
        padding-top: $grid * 2;
        position: relative;
        z-index: 1;
        display: inline-block;
        
        @media (min-width: 1012px) {
            right: 35px;
        }
    }

    .nav-mobile {
        display: none;
        margin-top: $grid * 2;
        margin-bottom: $grid * 2;
        
        .trigger {
            font-size: 1.5em;
            display: block;
            margin-bottom: $grid;
        }
        
        ul {
            display: none;
        }
        
        li {
            margin-bottom: 2px;
            
            &:last-child {
                margin-bottom: 0;
            }
        }
        
        @media (max-width: 1012px) {
            display: block;
        }
    }
    
    .nav-desktop {
        position: relative;
        top: -$grid * 9;
        left: -$grid * 3;
        
        ul {
            display: flex;
            justify-content: center;
        }
        
        li {
            &.padded {
                margin-right: $grid * 32;
            }
            
            &:after {
                content: ' | ';
                display: inline-block;
                padding-left: $grid * 2;
                padding-right: $grid * 2;
            }
            &:last-child, &.padded {
                &:after {
                    content: '';
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
        
        @media (max-width: 1012px) {
            display: none;
        }
    }
}
