.news {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    
    .item {
        margin-left: $grid;
        margin-right: $grid;
        margin-bottom: $grid * 4;
        text-align: center;
        width: 30%;
        
        &:first-child {
            margin-left: 0;
        } &:last-child {
            margin-right: 0;
        }
        
        .image-wrapper {
            position: relative;
            display: block;
        }
        
        .hover-layer {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(#000, 0.2);
            opacity: 0;
            
            display: block;
        }
        
        .hover-title {
            position: absolute;
            top: $grid * 2;
            right: $grid * 2;
            bottom: $grid * 2;
            left: $grid * 2;
            color: #fff;
            font-weight: 700;
            font-size: 1.1428571428571428571428571428571em;
            border: 1px solid #999;
            
            display: none;
        }
        
        .txt {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
        }
        
        .title {
            font-weight: 700;
            font-size: 0.92857142857142857142857142857143em;
            margin-top: $grid;
            display: block;
        }
        
        img {display: block;}
        
        a {
            display: block;
            
            &:hover, &:focus {
                .hover-layer {
                    opacity: 1;
                }
                
                .hover-title {
                    display: block;
                }
                
                .title {
                    opacity: 0;
                }
            }
        }
    }
}