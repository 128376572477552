$grid: 8px;
$yellow: #fefbec;
$black: #000;
$white: #fff;
$blue: #0069AA;
$grey: #5b5a57;
$timesNew: 'Times New Roman';

%more {
    text-align: center;
    
    a {
        background: url('../img/arrDown.png') no-repeat 100% 50%;
        display: inline-block;
        font-size: 14px;
        text-transform: uppercase;
        padding-right: $grid * 5;
        padding-top: $grid;
        padding-bottom: $grid;
    }
}