* {
    &, &:before, &:after {
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
            -ms-box-sizing: border-box;
             -o-box-sizing: border-box;
                box-sizing: border-box;
    }
}

body {
    padding: 0;
    margin: 0;
    background-color: $yellow;
}

body, td {
    font-family: $timesNew;
    font-size: 14px;
    color: $grey;
    letter-spacing: 0.06em;
}

img, iframe, audio, video, object, embed {
    max-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    font-size: inherit;
    font-weight: normal;
}

a {
    color: $grey;
    text-decoration: none;
    outline: none;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.hidden {
    display: none;
}

.width-centered {
    margin-left: auto;
    margin-right: auto;
    width: 980px;
    
    @media (max-width: 1012px) {
        width: auto;
        margin-left: $grid;
        margin-right: $grid;
    }
}

.relative {
    position: relative;
}

.text-center {
    text-align: center;
}

.guidance-popin-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 6;
    background-color: rgba(#000, 0.4);
    display: none;
    
    &.active {
        display: block;
    }
}

.guidance-popin {
    position: fixed;
    z-index: 7;
    background-color: $yellow;
    width: 68.75 * $grid;
    height: $grid * 61.25;
    display: none;
    padding: 15px;
    box-shadow: 0 0 $grid * 2 rgba(#000, 0.3);
    
    &.active {
        display: block;
    }
    
    .title {
        font-weight: bold;
        text-transform: uppercase;
    }
    
    .content {
        overflow: auto;
        height: 100%;
    }
    
    .close {
        position: absolute;
        top: $grid;
        right: $grid;
        background-color: $yellow;
        z-index: 10;
        
        a {
            background: url('../img/closeBtn.png') no-repeat 50% 50%;
            display: block;
            width: $grid * 4;
            height: $grid * 4;
        }
    }
    
    .mCSB_scrollTools {
        .mCSB_dragger {
            .mCSB_dragger_bar {
                background-color: #b0d5be;
            }
        }
        
        .mCSB_draggerRail {
            background-color: #d2cfc3;
        }
    }
}

.cookie-message-warning {
    position: fixed;
    z-index: 40;
    top: 0;
    left: 0;
    right: 0;
    
    padding-top: $grid * 2;
    padding-bottom: $grid * 2;
    background-color: rgba(#fff, 0.9);
    border-bottom: 1px solid #000;
    
    .close {
        position: absolute;
        z-index: 1;
        top: 8px;
        right: 16px;
    }
}
