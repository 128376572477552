.about-page {
    text-align: center;
    padding-top: $grid * 2;
    font-weight: bold;
    
    h1 {
        font-weight: bold;
        font-size: 1.2em;
        text-transform: uppercase;
        margin-bottom: $grid * 4;
    }
}
