#main-footer {
    .gototop {
        position: fixed;
        right: $grid * 5;
        bottom: $grid * 5;
        
        width: $grid * 4;
    }
}

.footer {
    font-size: 0.71428571428571428571428571428571em;
    text-align: center;
    margin-top: $grid * 4;
    
    .items {
        display: flex;
        justify-content: center;
        margin-bottom: $grid;
    }
    
    .item {
        &:after {
            content: ' | ';
        }
        
        &:last-child:after {
            content: '';
        }
        
        @media (max-width: 720px) {
            &:after {
                content: '';
            }
            
            margin-left: $grid;
            margin-right: $grid;
        }
    }
    
    .social {
        padding-bottom: $grid;
        
        ul {
            display: flex;
            justify-content: center;
        }
        
        .sprite {
            background: url('../img/social-icons-sprite.png') no-repeat;
            display: block;
            width: 29px;
            height: 27px;
            
            margin-left: 2px;
            margin-right: 2px;
        }
        
        .facebook {
            background-position: 0% 50%;
        } .instagram {
            background-position: -32px 50%;
        } .twitter {
            background-position: -65px 50%;
        }
    }
}
