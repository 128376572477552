@import "fa-variables";
@import "font-awesome";
@import "panel-theme";

@import 'slick';
@import 'slick-base-theme';
@import 'jquery.mCustomScrollbar';
@import 'vars';

@import 'common';
@import 'header';
@import 'footer';
@import 'home';
@import 'board';
@import 'talent';
@import 'about';
@import 'become';
@import 'news';
@import 'contact';
