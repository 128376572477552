.talent-page {
    .orbit-slide {
        display: flex;
        justify-content: center;
        text-align: center;
        
        .image {
            position: relative;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
            display: block;
            
            .shape {
                width: 4096px;
            }
            
            .real {
                z-index: 1;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
            }
        }
    }
    
    .medias {
        padding-left: $grid * 6;
        padding-right: $grid * 6;
        
        .slick-arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            
            width: 34px;
            height: 34px;
            
            outline: none;
            
            &:before {
                display: none;
            }
        }
        
        .slick-disabled {
            opacity: 0;
        }
        
        .slick-prev {
            left: $grid;
            background: url('../img/arrRight.png') no-repeat 50% 50%;
            transform: translateY(-50%) rotate(180deg);
        }
        .slick-next {
            right: $grid;
            background: url('../img/arrRight.png') no-repeat 50% 50%;
        }
    }
    
    .page-left,
    .page-right {
        width: 50%;
        padding: 0 1px;
        position: relative;        
    }
    
    #print-popin {
        background-color: rgba(#000, 0.5);
        position: fixed;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        
        .close {
            z-index: 1;
            position: absolute;
            top: $grid;
            right: $grid;
        }
        
        .modal {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }
        
        .vertical-align {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            z-index: 2;
        }
        
        .content {
            width: 405px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            position: relative;
            
            background-color: #fff;
            border-radius: $grid / 2;
            
            padding: $grid * 3 $grid * 4;
        }
    }
}

.talent-presentation-in-page {
    .talent-presentation {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: $grid * 2;
    }
    
    .simple, .close {display: none;}
}

.specs-bar {
    margin-top: $grid * 3;
    
    .simple {
        display: flex;
        align-items: center;
        
        & > * {
            width: 33%;
            text-align: center;
        }
        
        .name {margin-bottom: 0;}
        
        .more {
            text-align: right;
            text-transform: uppercase;
        }
    }
    
    .extended {
        display: none;
    }
    
    &.shown {
        .simple {
            display: none;
        }
        
        .extended {
            display: block;
        }
    }
}

.talent-presentation {
    border: 2px #090507 solid;
    text-align: center;
    padding: $grid * 2;
    position: relative;
    
    .close {
        position: absolute;
        top: $grid;
        right: $grid;
        
        a {
            background: url('../img/closeBtn.png') no-repeat 50% 50%;
            width: 34px;
            height: 34px;
            display: block;
        }
    }
    
    .name {
        font-size: 1.4285714285714285714285714285714em;
        padding: 0;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: $grid;
        line-height: 1em;
    }
    
    .specs {
        margin-top: $grid * 2;
        margin-bottom: $grid * 2;
        
        li {
            display: inline-block;
            margin: 0 $grid / 2;
            text-transform: none;
            font-size: 0.85714285714285714285714285714286em;
        }
    }
    
    .actions {
        margin-top: $grid * 2;
        margin-bottom: $grid * 2;
        font-weight: bold;
        text-transform: uppercase;
            
        li {
            display: inline-block;
        }
    }
    
    .nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        text-transform: uppercase;
        
        & > * {
            width: 33%;
        }
        
        .fa-twitter {
            color: #000;
            font-size: 2.2em;
        }
        
        .instagram-icon {
            display: block;
            width: 40px;
            height: 40px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.social {
    .sprite {
        background: url(../img/social-icons-sprite.png) no-repeat;
        display: block;
        width: 29px;
        height: 27px;
        margin:auto;
    }
    .instagram {
        background-position: -32px 50%;
    }
    .twitter {
        background-position: -65px 50%;
    }
}