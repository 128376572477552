.become-page {
    padding-left: $grid * 18;
    padding-right: $grid * 18;
    
    .fields {
        justify-content: space-between;
        display: flex;
        
        @media (max-width: 920px) {
            flex-wrap: wrap;
            justify-content: center;
        }
    }
        
    .title {
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: $grid * 3;
        font-size: 1.2em;
    }
    
    .form {
        display: flex;
        margin-top: 2px;
        margin-bottom: 2px;
        
        label {
            width: 106px;
            position: relative;
            top: $grid / 2;
        }
        
        textarea,
        [type="text"],
        [type="email"],
        [type="phone"] {
            border-radius: 2px;
            border: 1px solid #666;
            padding: 4px 3px 6px;
            width: 198px;
        }
    }
    
    .note {
        font-weight: bold;
        font-size: 0.85714285714285714285714285714286em;
        color: $blue;
    }
    
    .container-field-upload {
        display: flex;
        align-items: center;
        
        .label {
            width: 122px;
            text-align: left;
        }
        
        label {
            display: flex;
            border: 1px solid #666;
            border-radius: $grid / 2;
            overflow: hidden;
            text-transform: uppercase;
        }
        
        .white {
            background-color: #fff;
            display: block;
            text-align: left;
            width: 100px;
            position: relative;
            font-size: 0.85714285714285714285714285714286em;
        }
        
        .status {
            position: absolute;
            left: $grid / 2;
            top: 50%;
            transform: translateY(-50%);
            
            &.uploaded {
                color: green;
            }
        }
        
        @media (max-width: 920px) {
            &:last-child {
                margin-bottom: $grid * 3;
            }
        }
    }
    
    .btn {
        background-color: $blue;
        color: #fff;
        display: block;
        cursor: pointer;
        border: 0;
        font-family: inherit;
        
        padding: $grid / 2 $grid * 2;
    }
    
    .container-submit-become-form {
        text-align: right;
        
        .btn {
            font-size: 14px;
            text-transform: uppercase;
            border-radius: 2px;
            display: inline-block;
        }
    }
    
    .container-robot-security {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: $grid * 2;
        
        .g-recaptcha {
            & > * {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}
